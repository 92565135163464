import React from "react"
// import PostList from "../components/PostList"
import Layout from "../components/layout"
// import { useStaticQuery, graphql } from "gatsby"
// import VideoBanner from "../components/VideoBanner/VideoBanner"
// import SectionBox from "../components/SectionBox"
// import SectionSeparator from "../components/SectionHorizontalSeparator"
// import Anim from "../components/Anim"
// import TagBox from "../components/TagBox"
// import { StaticImage } from "gatsby-plugin-image"

const OfferList = () => {
  return (
    <Layout>
      <h1>Offer List Page</h1>
    </Layout>
  )
}

export default OfferList
